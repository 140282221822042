import React from 'react'

import ContactOne from '../components/contact-us/ContactOne'
import Layout from '../components/layout'

const Services = props => (
  <Layout lang="en" title="Contact Us" meta={{description: 'Let\'s get the conversation started. We\'d love to talk to you about how we can help turn your idea into reality.'}} rootProps={{ className: 'page contact-us' }}>
    <ContactOne lang="en"/>
  </Layout>
)

export default Services
